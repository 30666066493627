import { captureException, captureMessage } from '@sentry/nextjs'
import { fromUint8Array } from 'js-base64'

const mimeTypes: Record<string, string> = {
	rtf: 'application/rtf',
	doc: 'application/msword',
	dot: 'application/msword',
	pdf: 'application/pdf',
	xls: 'application/x-msexcel',
	ppt: 'application/mspowerpoint',
	txt: 'text/plain',
	xml: 'text/xml',
	html: 'text/html',
	png: 'image/png',
	jpeg: 'image/jpeg',
	jpg: 'image/jpeg',
	gif: 'image/gif',
}

export async function urlToBase64(url: string): Promise<string> {
	return new Promise((res, rej) => {
		const xhr = new XMLHttpRequest()
		xhr.onload = function () {
			const reader = new FileReader()
			reader.onloadend = function () {
				const u8s = new Uint8Array(reader.result as ArrayBuffer)
				const base64string = fromUint8Array(u8s)
				res(base64string)
			}
			reader.readAsArrayBuffer(xhr.response)
			reader.onerror = function () {
				rej('Failed to create base64 from url')
			}
		}
		xhr.open('GET', url)
		xhr.responseType = 'blob'
		xhr.send()
	})
}

export async function fileToBase64(file: File | Blob) {
	try {
		const buff = await file.arrayBuffer()
		const u8s = new Uint8Array(buff)
		const base64string = fromUint8Array(u8s)
		if (base64string.length === 0) {
			captureMessage(
				`fileToBase64 error: successfully decoded to empty string, buffer size: ${buff.byteLength}, Uint8Array size: ${u8s.length}`,
				'warning'
			)
		}
		return base64string
	} catch (e: any) {
		captureException(`fileToBase64 error`, {
			extra: {
				message: e.message,
			},
		})
	}
}

export function getExtension(fileName: string) {
	const splitFile = fileName.split('.')
	return splitFile[splitFile.length - 1].toLowerCase()
}

export const getMimeTypeFromExtension = (fileName: string) => {
	const extension = getExtension(fileName)

	return mimeTypes[extension]
}

export const convertToMegabytes = (bytes: number) => bytes / (1024 * 1024)

export const roundToOneDecimalPoint = (value: number) => {
	const result = Math.round(value * 10) / 10
	return result === 0 ? 0.0 : result
}

export const convertToMBAndRoundToOneDecimalPoint = (value: number) => {
	const x = convertToMegabytes(value)
	return roundToOneDecimalPoint(x)
	//this part needs some updates, now if the size is less than 100 KB it is returning 0 MB because the rounding of one decimals
	//WEBP-3539
}

export const convertFileSize = (value: number) => {
	const sizes = ['Bytes', 'KB', 'MB']
	if (value == 0) return 0
	const i = Math.floor(Math.log(value) / Math.log(1024))
	if (i == 0) return value + ' ' + sizes[i]
	return (
		(value / Math.pow(1024, i)).toFixed(sizes[i] === 'MB' ? 1 : 0) +
		' ' +
		sizes[i]
	)
}
