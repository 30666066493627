import { renderToStaticMarkup } from 'react-dom/server'
import axios from 'axios'
import { getStoryblokApi } from '@storyblok/react'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
import { RichTextResolverService } from './richtextResolver'
import { captureException } from '@sentry/nextjs'

const stripHtmlTags = (value: string) => {
	value = value.replace(/<\/li><li>/gi, '\n')
	return value.replace(/(<([^>]+)>)/gi, '').trim()
}

export const STORYBLOK_IGNORE_LIST = [
	'_',
	'additional-pages/',
	'admin/',
	'data/',
	'test/',
]

export enum StoryblokLanguage {
	English = 'default',
	Germany = 'de',
	Austria = 'de',
	Croatia = 'hr',
	Poland = 'pl',
	Ukraine = 'uk',
	Hungary = 'hu',
	Slovenia = 'sl',
	Czechia = 'cs',
	Slovakia = 'sk',
	Greece = 'el',
	Turkey = 'tr',
	Romania = 'ro',
	Bulgaria = 'bg',
	Dutch = 'nl',
	Albanian = 'sq',
}

export class StoryblokService {
	storyblokApi: ReturnType<typeof getStoryblokApi>
	version: SBVersion
	language: StoryblokLanguage
	countryCode: string

	constructor(
		version: SBVersion,
		countryCode: string,
		language: StoryblokLanguage,
		storyblokApi: ReturnType<typeof getStoryblokApi>
	) {
		this.storyblokApi = storyblokApi
		this.version = version
		this.language = language
		this.countryCode = countryCode
	}

	public async getStory<T extends Record<string, unknown>>(
		slug: `/${string}`,
		params: ISbStoriesParams = {}
	) {
		try {
			const encodedSlug = encodeURI(slug)

			const response: StoryResponse<StoryType<StoryContentType<T>>> =
				await this.storyblokApi.get(
					`cdn/stories/${this.countryCode}${encodedSlug}`,
					{
						version: this.version,
						language: this.language,
						...params,
					}
				)

			return response.data.story
		} catch (e: any) {
			const status = e?.response?.status

			if (status !== 404) {
				captureException(e, {
					extra: {
						formattedMessage: 'Failed to getStory',
						countryCode: this.countryCode,
						status,
						slug,
						encodedSlug: encodeURI(slug),
					},
				})
			}

			return null
		}
	}

	public async getStories<T extends Record<string, unknown>>({
		starts_with,
		...rest
	}: ISbStoriesParams = {}) {
		try {
			const response = (await this.storyblokApi.get('cdn/stories/', {
				version: this.version,
				starts_with: `${this.countryCode}`.concat(starts_with || ''),
				language: this.language,
				...rest,
			})) as StoriesResponse<T>

			return response.data.stories
		} catch (e: any) {
			const status = e?.response?.status

			if (status !== 404) {
				captureException(e, {
					extra: {
						formattedMessage: 'Failed to getStories',
						countryCode: this.countryCode,
						status,
						starts_with,
					},
				})
			}

			return null
		}
	}

	public async getAllStories(): Promise<
		Array<{
			full_slug: string
			published_at: string
			countryCode: string
			default_full_slug: string
		}>
	> {
		const options = {
			version: this.version,
			starts_with: this.countryCode,
			per_page: 100,
			page: 1,
		}

		return await this.storyblokApi
			.get('cdn/stories/', options)
			.then(async (response: StoriesResponse<StoryContentType<any>>) => {
				const { total } = response.headers ?? {}
				const maxPage = Math.ceil(total / options.per_page)

				const contentRequests = []
				for (let page = 1; page <= maxPage; page++) {
					contentRequests.push(
						this.storyblokApi.get('cdn/stories/', {
							...options,
							page,
						})
					)
				}

				return await axios
					.all(contentRequests)
					.then(
						axios.spread(async (...responses) => {
							let records: Array<StoryType<any>> = []
							responses.forEach((response) => {
								const data = response.data
								records = records.concat(data.stories)
							})

							return records.map(
								({ full_slug, published_at, default_full_slug }) => ({
									full_slug,
									default_full_slug,
									published_at,
									countryCode: this.countryCode,
								})
							)
						})
					)
					.catch((e: any) => {
						captureException(e, {
							extra: {
								formattedMessage: `Failed to format all stories. Error: ${e?.message}`,
							},
						})
						return null
					})
			})
			.catch((e: any) => {
				captureException(e, {
					extra: {
						formattedMessage: `Failed to get all stories. Error: ${e?.message}`,
					},
				})
				return null
			})
	}

	public async getLinks(path: `/${string}` = '/') {
		try {
			const { data }: SBLinksResponse = await this.storyblokApi.get(
				'cdn/links/',
				{
					starts_with: `${this.countryCode}`.concat(path),
					version: this.version,
				}
			)

			return data.links
		} catch (e: any) {
			const status = e?.response?.status

			if (status !== 404) {
				captureException(e, {
					extra: {
						formattedMessage: 'Failed to getLinks',
						countryCode: this.countryCode,
						status,
						path,
					},
				})
			}

			return null
		}
	}

	public async getMetatags(storyblokPath?: string | false) {
		let data: SEOMetatags | null = null

		try {
			const response: StoryResponse<StoryType<StoryContentType<SEOMetatags>>> =
				await this.storyblokApi.get(
					`cdn/stories/${this.countryCode}/data/metatags`,
					{
						version: this.version,
						language: this.language,
					}
				)

			data = response.data.story.content
		} catch (e: any) {
			const status = e?.response?.status

			if (status !== 404) {
				captureException(e, {
					extra: {
						formattedMessage: 'Failed to getMetatags default',
						countryCode: this.countryCode,
						status,
						storyblokPath,
					},
				})
			}
		}

		if (storyblokPath) {
			const encodedStoryblokPath = encodeURI(storyblokPath)

			try {
				const response: StoryResponse<
					StoryType<
						StoryContentType<
							SEOMetatags & {
								isIndexable?: boolean
								header: Array<{
									secondary_text?: StoryblokRichtext
								}>
							}
						>
					>
				> = await this.storyblokApi.get(
					`cdn/stories/${this.countryCode}${encodedStoryblokPath}`,
					{
						version: this.version,
						language: this.language,
					}
				)

				const { story } = response.data

				const getTitle = () => {
					const translatedSlug = story.translated_slugs?.find(
						({ lang }) => lang === this.language
					)

					const translatedName = translatedSlug?.name

					if (!translatedName) {
						return `${story.name} | Trenkwalder`
					}

					return `${translatedName} | Trenkwalder`
				}

				const getHeaderDescription = () => {
					const description = story.content.header?.[0]?.secondary_text

					if (!description) {
						return null
					}

					return new RichTextResolverService(description).baseResolve()
				}

				const title = getTitle()
				const headerDescription = stripHtmlTags(
					renderToStaticMarkup(getHeaderDescription())
				)

				const defaultMetatags = data ? data.metatags : {}
				const newMetatags: SEOMetatags['metatags'] = story.content.metatags

				data = {
					site_name: '',
					...(data && data),
					metatags: {
						isIndexable: story.content.isIndexable === false ? false : true,
						title: newMetatags?.title || title,
						description:
							newMetatags?.description ||
							headerDescription ||
							defaultMetatags.description ||
							'Your first step towards a new job begins with your application to Trenkwalder.',
						og_title: newMetatags?.og_title || title,
						og_description:
							newMetatags?.og_description ||
							headerDescription ||
							defaultMetatags.og_description ||
							'Your first step towards a new job begins with your application to Trenkwalder.',
						og_image: newMetatags?.og_image || defaultMetatags.og_image,
						twitter_title: newMetatags?.twitter_title || title,
						twitter_description:
							newMetatags?.twitter_description ||
							headerDescription ||
							defaultMetatags.twitter_description ||
							'Your first step towards a new job begins with your application to Trenkwalder.',
						twitter_image:
							newMetatags?.twitter_image || defaultMetatags.twitter_image,
					},
				}
			} catch (e: any) {
				const status = e?.response?.status

				if (status !== 404) {
					captureException(e, {
						extra: {
							formattedMessage: 'Failed to getMetatags custom',
							countryCode: this.countryCode,
							status,
							storyblokPath,
							encodedStoryblokPath,
						},
					})
				}
			}
		}

		return data
	}

	public async getCountryGTMTags() {
		try {
			const response: StoryResponse<StoryType<StoryContentType<GTMType>>> =
				await this.storyblokApi.get(
					`cdn/stories/${this.countryCode}/admin/ga-gtm`,
					{
						version: this.version,
					}
				)

			return response.data.story.content
		} catch (e: any) {
			captureException(e, {
				extra: {
					formattedMessage: 'Failed to getCountryGTMTags',
					countryCode: this.countryCode,
					status: e?.response?.status,
				},
			})

			return null
		}
	}

	public async getRobotsTxt() {
		try {
			const response: StoryResponse<StoryType<StoryContentType<RobotsData>>> =
				await this.storyblokApi.get(
					`cdn/stories/${this.countryCode}/admin/robots-txt`,
					{ version: this.version }
				)

			return response.data.story.content
		} catch (e: any) {
			captureException(e, {
				extra: {
					formattedMessage: 'Failed to getRobotsTxt',
					countryCode: this.countryCode,
					status: e?.response?.status,
				},
			})

			return null
		}
	}

	public async getCountriesData() {
		try {
			const countriesResponse: StoryResponse<
				StoryType<{
					title: string
					description?: string
					countries: Array<StoryContentType<CountryType>>
				}>
			> = await this.storyblokApi.get('cdn/stories/group/admin/countries', {
				version: this.version,
				language: this.language,
			})

			return countriesResponse.data.story.content
		} catch (e: any) {
			captureException(e, {
				extra: {
					formattedMessage: 'Failed to getCountriesData',
					countryCode: this.countryCode,
					status: e?.response?.status,
				},
			})

			return null
		}
	}

	public async getNavigation(): Promise</* NavigationData */ any | null> {
		try {
			const response = await this.storyblokApi.get(
				`cdn/stories/${this.countryCode}/data/navigation`,
				{ version: this.version, language: this.language }
			)

			return response.data.story.content
		} catch (e: any) {
			captureException(e, {
				extra: {
					formattedMessage: 'Failed to getNavigation',
					countryCode: this.countryCode,
					status: e?.response?.status,
				},
			})
		}

		return null
	}

	public async getSideMenuLinks(
		path: `/${string}`
	): Promise<Array<SideMenuLink>> {
		const stories = await this.getStories<StoryType<{ order: string }>>({
			// Get stories within folder path
			by_slugs: `*${path}/*`,
			// Ignore folders that are nested within current folder
			excluding_slugs: `*${path}/*/*`,
			filter_query: {
				component: {
					in: 'main_nestable_page,nestable_page',
				},
			},
		})

		if (!stories) {
			return []
		}

		const sorted = stories.sort((a, b) => {
			const orderA = a.content.order ? +a.content.order : 0
			const orderB = b.content.order ? +b.content.order : 0
			if (orderA > orderB) {
				return 1
			} else if (orderA < orderB) {
				return -1
			}

			return 0
		})

		return sorted.map((story) => {
			let pageName = story.name

			if (this.language !== StoryblokLanguage.English) {
				const translatedSlug = story.translated_slugs?.find((ts) => {
					return ts.lang === this.language
				})

				if (translatedSlug && translatedSlug.name) {
					pageName = translatedSlug.name
				}
			}

			let fullSlug = path

			if (!story.is_startpage) {
				fullSlug += `/${story.slug}`
			}

			return { pageName, fullSlug }
		})
	}
}

export type SBVersion = 'draft' | 'published'

export type StoryResponse<T extends Record<any, any>> = {
	data: {
		cv: number
		links: Array<unknown>
		rels: Array<unknown>
		story: T
	}
}

export type StoriesResponse<T extends Record<any, any>> = {
	headers: {
		total: number
	}
	data: {
		cv: number
		links: Array<SBLink>
		rels: Array<unknown>
		stories: Array<T>
	}
}

export type SBLink = {
	id: number
	is_folder: boolean
	is_startpage: boolean
	name: string
	parent_id: number | null
	path: unknown | null
	position: number
	published: boolean
	real_path: string
	slug: string
	uuid: string
}

export type SBLinksResponse = {
	data: {
		links: Record<string, SBLink>
	}
}

export type TranslatedSlug = {
	path: string
	name: string | null
	lang: StoryblokLanguage
	published: boolean | null
}

export type StoryType<T extends Record<any, any>> = {
	name: string
	created_at: Date
	published_at: Date
	id: number
	uuid: string
	content: T
	slug: string
	full_slug: string
	sort_by_date?: unknown
	position: number
	tag_list: Array<unknown>
	is_startpage: boolean
	parent_id: number
	meta_data?: unknown
	group_id: string
	first_published_at: Date
	release_id?: unknown
	lang: string
	path?: unknown
	alternates: Array<unknown>
	default_full_slug?: string
	translated_slugs?: Array<TranslatedSlug>
}

export type StoryContentType<T extends Record<any, any>> = T & {
	_uid: string
	component: string
	_editable: string
}

export type SBAsset<T extends 'required' | undefined = undefined> = {
	fieldtype?: 'asset'
	name?: string
	is_external_url?: boolean
	filename: T extends 'required' ? string : string | null
	alt: T extends 'required' ? string : string | null
	title?: T extends 'required' ? string : string | null
	id?: T extends 'required' ? number : number | null
	focus?: T extends 'required' ? string : string | null
	copyright?: T extends 'required' ? string : string | null
}

export type SEOMetatags = {
	site_name: string
	locale?: string
	domain?: string
	url?: string
	metatags: {
		isIndexable?: boolean
		title?: string
		description?: string
		og_title?: string
		og_description?: string
		og_image?: string
		twitter_title?: string
		twitter_description?: string
		twitter_image?: string
	}
}

type GTMType = {
	google_tag_manager_dev_staging: string
	google_tag_manager_prod: string
}

interface RobotsRule {
	label: 'Allow' | 'Disallow'
	value: string
}

interface RobotsData {
	user_agent?: string
	sitemap?: string
	rules: Array<StoryContentType<RobotsRule>>
}

export type SBPageContent = {
	// Use SectionHeaderProps instead of any
	header: Array<any>
	body: Array<StoryContentType<Record<string, unknown>>>
}

export type CountryType = {
	flag: SBAsset<'required'>
	name: string
	domain?: string
	country_code?: string
	en_domain?: string
}

type SBFormTypes =
	| 'text'
	| 'textarea'
	| 'number'
	| 'calendar'
	| 'checkbox'
	| 'select'
	| 'email'
	| 'phone'
	| 'radio'
	| 'image'
	| 'cv'
	| 'documents'
	| 'multiupload'
	| 'title'
	| 'info'
	| 'modal-checkbox'

export type FormShowInType =
	| 'open_application'
	| 'white_form'
	| 'blue_form'
	| 'cv_maker'
	| 'callback'
	| 'contact'
	| 'company_contact'
	| 'online_appointment'
	| 'job_alert'
	| 'productconfigurator'
	| 'subscribe_pdf'
	| 'gdpr_renewal'
	| 'insights_for_candidates'
	| 'insights_for_companies'

export interface FormItemOption {
	text: string
	value: string
	for_connexys?: boolean
	subject?: string
	recipient_email?: string
	cc?: string
	_uid?: string
}

export interface FormItemGroup {
	_uid: string
	title: string
	items: Array<FormItem>
}

export interface FormItem {
	_uid: string
	type: SBFormTypes
	label: StoryblokRichtext
	required: boolean
	blue_form: boolean
	white_form: boolean
	cv_maker: boolean
	callback: boolean
	description: string
	max_length?: number
	show_in: Array<FormShowInType>
	options: Array<FormItemOption>
	width?: 'col-span-2' | 'col-span-3' | 'col-span-4' | 'col-span-full'
	placeholder: string
	api_field_name: string
	picklist_field_name?: string
	open_application?: boolean
	start_year?: string
	end_year?: string
	add_to_assistant_block: boolean
	component: string
}

// https://github.com/storyblok/storyblok-js-client/blob/e337bbbc936988a6e65d5c21da9df4adb20cbc33/types/index.d.ts#L182
export type ISbStoriesParams = {
	token?: string
	with_tag?: string
	is_startpage?: 0 | 1
	starts_with?: string
	by_uuids?: string
	by_uuids_ordered?: string
	excluding_ids?: string
	excluding_fields?: string
	resolve_links?: 'url' | 'story' | '0' | '1'
	version?: 'draft' | 'published'
	resolve_relations?: string | string[]
	cv?: number
	sort_by?: string
	search_term?: string
	filter_query?: Record<string, unknown>
	per_page?: number
	page?: string
	from_release?: string
	language?: string
	fallback_lang?: string
	first_published_at_gt?: string
	first_published_at_lt?: string
	level?: number
	published_at_gt?: string
	published_at_lt?: string
	by_slugs?: string
	excluding_slugs?: string
}

export type SideMenuLink = {
	pageName: string
	fullSlug: string
}

export type SBItem = {
	_uid: string
} & Record<string, any>
